.LoginContainer{
    position: fixed;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background: #304352;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #d7d2cc, #304352);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #d7d2cc, #304352); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}

.LoginContainer .LoginBody {
    position: relative;
    display: flex;
    width: 50%;
    height: 50%;
}

.LoginContainer .LoginBody .LoginLeft {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.LoginContainer .LoginBody .LoginLeft .logo{
    width: 90%;
    height: 100%;
}

.LoginContainer .LoginBody .LoginRight {
    width: 50%;
    border-radius: 5%;
    display: grid;
    justify-content: center;
    align-items: center;
    background: linear-gradient(45deg, white 20%, #3c3c3c 20% 50%, #3c3c3c 50% 80%, white 80%);
    box-shadow: 8px 8px 13px -9px rgba(0,0,0,0.64);
    -webkit-box-shadow: 8px 8px 13px -9px rgba(0,0,0,0.64);
    -moz-box-shadow: 8px 8px 13px -9px rgba(0,0,0,0.64);
}

.LoginContainer .LoginBody .LoginRight .label-title {
    font-size: 1rem;
    color: white;
}

.LoginContainer .LoginBody .LoginRight .button {
    margin-top: 2%;
    font-size: 1rem;
    color: white;
    display: flex;
    justify-content: right;
}
