.formAlert {
    width: 130%;
    height: 130%;
    padding: 2%;
    overflow: hidden;
    position: absolute;
    background: rgba(0,0,0,0.7);
    align-items: 'center';
    border-radius: 10px;
    justify-content: center;
    margin-top: -11%;
    margin-left: -21%;
    z-index: 9999999999;
  }

.formAlertContent {
    margin-top: 0;
    width: 400px;
    height: 300px;
    padding: 10px;
    border-radius: 1rem;
    position: fixed;
    padding: 20px;
    overflow: hidden;
    margin-top: 10%;
    background-color: white;
}

.alert-header {
    justify-content: center;
    width: 100%;
    height: 30%;
    text-align: center;
}

.alert-header .logo {
    width: 120px;
    height: 100px;
}

.alert-body{
    width: 100%;
    height: 50%;
    text-align: center;
    margin-top: 5%;
}

.alert-body span{
    font-size: 22px;
}

.alert-footer{
    width: 100%;
    display: grid;
    justify-content: center;  
}