.TopUpFormContent {
    position: relative;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.TopUpFormContent .form {
    position: relative;
    justify-content: space-between;
    display: flex;
    width: 100%;
    height: 100%;
    box-sizing: 0;
    padding: 20px;
}

.TopUpFormContent  .leftContent{
    position: relative;
    width: 60%;
    height: 100%;
    background-color: whitesmoke;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.TopUpFormContent  .leftContent .leftHeader{
    position: relative;
    width: 100%;
    height: 20%;
}

.TopUpFormContent  .leftContent .leftHeader .leftTitle{
    position: relative;
    display: flex;
    width: 100%;
    justify-content: center;
    padding-top: 2%;
    font-size: 1.3rem;
    font-weight: bold;
}


.TopUpFormContent  .leftContent .leftBody{
    position: relative;
    width: 100%;
    height: 100%;
    margin-left: 10%;
}

.TopUpFormContent  .leftContent .leftBody .txtGiftCardNO{
    position: relative;
    width: 80%;
    margin-top: 2%;
}

.TopUpFormContent  .leftContent .leftBody .txtTopUpAmount{
    position: relative;
    width: 80%;
    margin-top: 2%;
}


.TopUpFormContent  .leftContent .leftBody .cbPaymentType{
    position: relative;
    width: 80%;
    margin-top: 2%;
}



.TopUpFormContent  .rightContent{
    position: relative;
    width: 40%;
    height: 100%;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: ghostwhite;
}

  

.TopUpFormContent  .rightContent .rightBody{
    position: relative;
    width: 100%;
    height: 70%;
    padding: 10px;
    overflow: auto;
    overflow-y: scroll; 
    overflow-x: auto hidden; 
}


.TopUpFormContent  .rightContent .rightFooter .btnPayment{
    display: flex;
    justify-content: center;
}

.TopUpFormContent  .rightContent .rightFooter button{
    width: 40%;
}

