.PaymentBillContent{
    /* position: absolute; */
    width: 90%;
    height: 90%;
    margin-left: 5%;
    z-index: 9999999;
}

.PaymentBillContent .imgQR {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.PaymentBillContent .imgQR .logo {
    margin-top: 2%;
    position: relative;
    width: 300px;
    height: 300px;
}

.PaymentBillContent .BillHeader {
  margin-top: 1%;
}

.PaymentBillContent .BillHeader .BillTitle{
    font-size: 40px;
    font-weight: bold;
    text-align: center;
}

.PaymentBillContent .BillHeader .BillTitle2{
    font-size: 40px;
    font-weight: bold;
    text-align: center;
}


.PaymentBillContent .BillHeader .Queue{
    font-size: 30px;
    text-align: left;
}

.PaymentBillContent .BillHeader .Cashier{
    font-size: 30px;
    text-align: left;
}



.PaymentBillContent .BillHeader .BillNO{
    font-size: 30px;
    text-align: left;
}

.PaymentBillContent .BillHeader .Phone{
    font-size: 30px;
    text-align: left;
}

.PaymentBillContent .BillHeader .TableNo{
    font-size: 30px;
    text-align: left;
}


.PaymentBillContent .BillHeader .Cashier{
    font-size: 30px;
    text-align: left;
    margin-top: 5px;
}

.PaymentBillContent .BillHeader .ShowDate{
    font-size: 30px;
    text-align: left;
}

.PaymentBillContent .BillHeader .LocationName{
    font-size: 30px;
    text-align: left;
}

.PaymentBillContent .BillHeader .HeadLine{
    font-size: 30px;
    text-align: center;
}

.PaymentBillContent .BodyHeader {
    font-size: 30px;
    display: flex;
    justify-content: space-between;
    text-align: right;
}


.PaymentBillContent .BillBody .BodyDetails .Items {
   font-size:  30px;
}

.PaymentBillContent .BillBody .BodyDetails .Items .Detail {
    text-align: left;
}

.PaymentBillContent .BillBody .BodyDetails .Items .Detail .mds {
    display: flex;
    justify-content: space-between;
}

.PaymentBillContent .BillBody .BodyDetails .Items .Name .ProductName {
    font-size: 30px;
    font-weight: bold;
}

.PaymentBillContent .BillFooter {
    margin-top: 10px;
}


.PaymentBillContent .BillFooter .subtotal {
    font-size: 30px;
    display: flex;
    justify-content: space-between;
    font-weight: bold;
}

.PaymentBillContent .BillFooter .discout {
    font-size: 30px;
    display: flex;
    justify-content: space-between;
    font-weight: bold;
}

.PaymentBillContent .BillFooter .tax {
    font-size: 30px;
    display: flex;
    justify-content: space-between;
    font-weight: bold;
}

.PaymentBillContent .BillFooter .nettotal {
    font-size: 30px;
    display: flex;
    justify-content: space-between;
    font-weight: bold;
}

.PaymentBillContent .BillFooter .paymentType{
    font-size: 30px;
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
    font-weight: bold;
}


.PaymentBillContent .BillFooter .PaidMoreAmount {
    font-size: 30px;
    display: flex;
    justify-content: space-between;
    font-weight: bold;
}

.PaymentBillContent .BillFooter .paidAmount {
    font-size: 30px;
    display: flex;
    justify-content: space-between;
    font-weight: bold;
}

.PaymentBillContent .BillFooter .changeAmount {
    font-size: 30px;
    display: flex;
    justify-content: space-between;
    font-weight: bold;
}


.footer-line{
    width: 100%;
    margin-top: 1%;
    margin-bottom: 1%;
    border-top: 3px dashed #000; border-right: none; border-bottom: none; border-left: none;
  }

.PaymentBillContent .BillFooter .TaxBeforeVat {
    font-size: 30px;
    display: flex;
    justify-content: space-between;
}
.PaymentBillContent .BillFooter .VAT {
    font-size: 30px;
    display: flex;
    justify-content: space-between;
}
.PaymentBillContent .BillFooter .TotalTax {
    font-size: 30px;
    display: flex;
    justify-content: space-between;
}

.PaymentBillContent .BillFooter .Thankyou {
    font-size: 30px;
    text-align: center;
}


