.formPos {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    z-index: 999;
  }

  .OrderContent {
    width: 100%;
    height: 109%;
    position: fixed;
    display: flex;
    justify-content: left;
    left: 0;
    overflow: hidden;
    background-color: #dfe2eb;
    margin-top: -8%;
    z-index: 999;
  }

  .LeftContent {
    height: 88%;
    width: 15%;
    position: relative;
    margin-left: 1%;
    margin-top: 25px;
    margin-bottom: 25px;
    overflow: hidden;
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    transition: 0.5s;
    background: #000000;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #434343, #000000);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #434343, #000000); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    
  }

  .imgLogo{
    position: relative;
    height: 15%;
    width: 95%;
    margin-top: 5%;
    display: flex;
    justify-content: center;
  }
  
  .imgLogo .logo{
      width: 50%;
      height: 100%;
      border-radius: 1000px;
      position: relative;
      box-shadow: 0 0 20px #00000033;
  }

  .SubMenu {
    width: 100%;
    height: 72%;
    position: relative;
    overflow: auto;
    scrollbar-width: none;
    overflow-y: scroll;
    margin-top: 25%;
  }

  .SubMenu::-webkit-scrollbar {
    display: none;
  }

  .SubMenuContent {
    position: relative;
    width: 100%;
    height: 60px;
    background-color: transparent;
    padding: 5%;
    display: flex;
    font-size: 12px;
    margin-top: 10px;
    cursor: pointer;
    color: white;
  }
  
  .SubMenuContent img {
    width: 40px;
    height: 40px;
    padding: 10px;
    border-radius: 10px;
    position: relative;
  }

  
  .SubMenuContent:hover{
    background: #000046;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #1CB5E0, #000046);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #1CB5E0, #000046); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */    
    color: white;
  }
  
  .SubMenuContent .name {
    padding-top: 6%;
    padding-left: 5%;
    font-size: 14px;
  }
  
  /*  end of Left content */

  /*  start Center content */

  .CenterContent {
    height: 88%;
    width: 55%;
    position: relative;
    margin-top: 25px;
    margin-bottom: 25px;
    margin-left:4.6%;
    display: grid;
    justify-content: left;
    transform: translate(-10%);
    align-items: 'left';
    overflow: hidden;
    transition: 0.5s;
    background-color: #f2f2f2;
  }

  .CenterContent .CenterHeader{
    position: fixed;
    display:  flex;
    width: 100%;
    height: 100%;
    margin-top: 2%;
  }

  .user-name{
    position: relative;
    font-size: 20px;
    margin-left: 5%;
    font-weight: bold;
    color: black;
  }

  .CenterContent .CenterHeader .Search{
    position: relative;
    display:  flex;
    justify-content: right;
    height: 12%;
    width: 100%;
    margin-right: 2%;
    font-weight: bold;
    color: black;
  }

  .menuContent {
    position: fixed;
    display: flex;
    height: 12%;
    margin-top: 11%;
    margin-left: 5%;
    width: 93%;
  }

  .menuContent img {
    width: 40px;
    height: 40px;
    padding: 10px;
    border-radius: 10px;
    position: relative;
    background-color: wheat;
  }

  .menuContent .FoodMenu{
    position: relative;
    width: 10%;
    max-height: 100px;
    justify-content: left;
    margin-left: 1%;
    margin-bottom: 2%;
    cursor: pointer;
  }

  .menuContent .FoodMenu .FoodMenuContent{
    width: 70px;
    max-height: 85px;
    background-color: #ffffff;
    box-shadow: 5px 5px 5px #00000024;
    position: relative;
    text-align: center;
    font-size: 16px;
    border-radius: 15px;
    left: 0;
    border: 1.5px solid #d9d9d9;
    padding: 10px;
  }

  .menuContent .FoodMenu .FoodMenuContent .item-body{
    font-size: 13px;
    font-weight: bold;
  }

  .menuContent .FoodMenu .FoodMenuContent:hover{
    background-color: #e8e6e6;
  }

  .underLine {
    position: fixed;
    height: 10%;
    margin-top: 20%;
    margin-left: 5%;
    width: 93%;
  }

  .underLine span {
    font-weight: bold;
    font-size: 16px;
  }

  .MenuList {
    width: 93%;
    height: 65%;
    position: fixed;
    justify-content: left;
    display: flex;
    flex-wrap:  wrap;
    margin-top: 28%;
    margin-left: 5%;
    overflow: auto;
    scrollbar-width: none;
    overflow: hidden;
    overflow-y: scroll; 
  }

  .MenuList::-webkit-scrollbar {
    display: none;
  }

  .MenuList .item {
    width: 150px;
    position: relative;
    height: 180px;
    margin-top: 55px;
    background-color: #ffffff;
    box-shadow: 5px 5px 5px #00000024;
    text-align: center;
    font-size: 12px;
    border-radius: 15px;
    margin-left: 5px;
    left: 0;
    border: 1.5px solid #d9d9d9;
    cursor: pointer;
}

.MenuList .item:hover {
    background-color: #d9d9d9;
}

.MenuList .item .item-header {
    position: relative;
    height: 15%;
}


.MenuList .item .item-header .profile-img {
    width: 100px;
    height: 100px;
    border-radius: 1000px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    /* border: 8px solid #c74385; */
    box-shadow: 0 0 20px #00000033;
}

.MenuList .item .item-header .profile-img:hover {
    width: 120px;
    height: 120px;
    border: 4px solid #e8e8e6;
}

.MenuList .item .item-body {
    position: relative;
    padding-top: 5%;
}

.MenuList .item .item-body .name {
    margin-top: 15%;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    padding: 10px;
    font-size: 12px;
    font-weight: bold;
    color: black;
}

.MenuList .item .item-body .price {
    position: relative;
    font-size: 12px;
    font-weight: bold;
    color: black;
    margin-top: -10%;
}

.MenuList .item .item-body .avilable {
    position: relative;
    margin-top: 1%;
    font-size: 11px;
    color: black;
}


@media screen and (max-width: 100px) {
    .item {
        width: 96%;
    }

    .item .item-body {
        padding: 10px 20px;
    }
}

  /* end of center content */

  /* start of right content */

  .RightContent {
    height: 88%;
    width: 30%;
    position: relative;
    margin-left: -5.5%;
    margin-right: 1%;
    justify-content: left;
    margin-top: 25px;
    overflow: hidden;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    transition: 0.5s;
    box-shadow: 3px 3px 10px rgba(8, 8, 8, 0.2);
    background: #000000;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #434343, #000000);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #434343, #000000); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  }

  .billNo{
    width: 100%;
    margin-left: 5%;
    position: relative;
    display: flex;
    margin-top:6%;
  }
  
  .billNo .ordertext{
    font-weight: bold;
    font-size: 14px;
    color: white;
  }
  
  .billNo .tableNo{
    font-weight: bold;
    font-size: 14px;
    margin-left: 8%;
    color: white;
  }
  
  .billNo .btnclose{
    width: 50px;
    height: 30px;
    font-weight: bold;
    font-size: 14px;
    color: black;
    text-align: center;
    padding-top: 1%;
    border-radius: 1000px;
    margin-top: -3%;
    border: 1px solid white;
    margin-left: 45%;
    background-color: white;
  }
  
  .billNo .btnclose:hover{
    background-color: red;
    color: white;
  }
  
  .OrderHead{
    width: 100%;
    position: relative;
    display: flex;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 2%;
    justify-content: space-between;
    color: white;
    margin-top: 4%;
    border: 1px solid #d9d9d9;
  }
  
  .OrderHead .ProductName{
    font-weight: bold;
    font-size: 14px;
  }
  
  .OrderHead .Quantity{
    font-weight: bold;
    font-size: 15px;
    margin-right: 10%;
  }
  
  .OrderHead .Price{
    font-weight: bold;
    font-size: 15px;
  }

  .posRightSidenavContent {
    position: relative;
    display: flex;
    height: 50%;
    flex-wrap:  wrap;
    align-content: flex-start;
    scrollbar-width: none;
    overflow-y: scroll; 
    overflow-x: hidden; 
  }

  .posRightSidenavContent::-webkit-scrollbar {
    display: none;
  }


.posRightSidenavContent .orderContent{
    position: relative;
    width: 100%;
    height: 60px;
    margin-top: 2%;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 1%;
    background-color: gray;
    color: white;
}
.posRightSidenavContent .orderContent:hover{
    background: #373B44;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #4286f4, #373B44);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #4286f4, #373B44); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    color: white;
}

.posRightSidenavContent .orderContent .orderBody {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
}

.posRightSidenavContent .orderContent .orderBody .detail .productName{
  position: relative;
  width: 100%;
  display: flex;
  justify-content: left;
  font-size: 14px;
}

.posRightSidenavContent .orderContent .orderFooter{
  position: relative;
  display: flex;
  width: 100%;
  height:50%;
  text-align: left;
}

.posRightSidenavContent .orderContent .orderFooter .price{
    position: relative;
    display: flex;
    width: 80%;
    font-weight: bold;
    font-size: 12px;
}

.posRightSidenavContent .orderContent .orderFooter .quantity{
  margin-top: 2%;
  margin-left: 2%;
  font-weight: bold;
  font-size: 12px;
  justify-content: center;
}

.posRightSidenavContent .orderContent .orderFooter .plus{
  position: relative;
  width: 15%;
  height: 30px;
  display: flex;
  justify-content: center;
  border-radius: 1000px;
  padding: 1px;
  cursor: pointer;
  border: 1px solid white;
  font-size: 20px;
  font-weight: bold;
  color: white;
}

.posRightSidenavContent .orderContent .orderFooter .plus:hover{
  background-color: white;
  color: blue;
}

.posRightSidenavContent .orderContent .orderFooter .minus{
  position: relative;
  width: 15%;
  height: 30px;
  display: flex;
  margin-left: 2%;
  justify-content: center;
  border-radius: 1000px;
  cursor: pointer;
  border: 1px solid white;
  font-size: 20px;
  font-weight: bold;
  color: white;
}

.posRightSidenavContent .orderContent .orderFooter .minus:hover{
  background-color: red;
  color: white;
}
.posRightSidenavContent .orderContent .orderFooter .Subtotal{
  position: relative;
  width: 100%;
  height: 25%;
  display: flex;
  justify-content: right;
}

.posRightSidenavContent .orderContent .orderFooter .Subtotal p{
  font-size: 12px;
  font-weight: bold;
}

.footer-line{
    width: 100%;
    margin-top: 1%;
    margin-bottom: 1%;
    border-top: 1.8px dashed white; border-right: none; border-bottom: none; border-left: none;
  }

  .footer-subtotal .nettotal{
    position: relative;
    display: flex;
    margin-top: 1%;
    width: 100%;
    justify-content: space-between;
  }
  
  .footer-subtotal .nettotal span{
    font-weight: bold;
    font-size: 16px;
  }
  
  .footer-subtotal .nettotal strong{
    font-weight: bold;
    font-size: 15px;
  }
  
  .footer-subtotal .nettotal span{
    font-weight: bold;
    font-size: 15px;
  }
  
.posRightSidenavBottom {
    height: 28%;
    width: 90%;
    position: relative;
    overflow: hidden;
    padding-top: 1%;
    margin-left: 5%;
    margin-bottom: 5px;
    color: white;
  }
  
  .posRightSidenavBottom .takeAway{
    position: relative;
    display: flex;
    margin-top:  5%;
    width: 100%;
    justify-content: center;
  }

  .paymentMethod{
    position: relative;
    width: 100%;
    height: 30%;
    margin-top: 3%;
    display: flex;
    justify-content: space-between;
  }
  .paymentMethod .btnpayment{
    width: 100px;
    height: 50px;
    text-align: center;
    padding: 15px;
    border: 1px solid white;
    border-radius: 9px;
    color: white;
    font-size: 10px;
    cursor: pointer;
  }
  
  .paymentMethod .btnpayment:hover{
    background-color: blue;
    font-size: 12px;
    color:white;
  }
  
  .paymentMethod .btnPrintFood{
    width: 100px;
    height: 50px;
    text-align: center;
    padding: 15px;
    border: 1px solid greenyellow;
    border-radius: 9px;
    color: white;
    font-size: 10px;
    cursor: pointer;
  }
  
  .paymentMethod .btnPrintFood:hover{
    background-color: green;
    font-size: 12px;
    color:white;
  }
  
  .paymentMethod .btnPrintDrink{
    width: 100px;
    height: 50px;
    text-align: center;
    padding: 15px;
    border: 1px solid orange;
    border-radius: 9px;
    color: white;
    font-size: 10px;
    cursor: pointer;
  }
  
  .paymentMethod .btnPrintDrink:hover{
    background-color: orange;
    font-size: 12px;
    color:white;
  }

  .paymentMethod .btnChangeTable{
    width: 100px;
    height: 50px;
    text-align: center;
    padding: 15px;
    border: 1px solid red;
    border-radius: 9px;
    color: white;
    font-size: 10px;
    cursor: pointer;
  }
  
  .paymentMethod .btnChangeTable:hover{
    background-color: red;
    font-size: 12px;
    color:white;
  }