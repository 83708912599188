
.admin_container{
    position: fixed;
    width: 100%;
    height: 100%;
    box-sizing: 0;
    overflow: hidden;
    z-index: 0;
    font-family: Phetsarath OT;
}

.admin_container .navbarContent {
    position: fixed;
    width: 83%;
    height: 11%;
    padding: 1%;
    margin-left: 17%;
    box-sizing: 0;
    display: inline-flex;
    justify-content: right;
    z-index: 0;
    font-size: 1.2rem;
    background: #232526;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #414345, #232526);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #414345, #232526); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    box-shadow: 5px 5px 5px #00000024;
}


.admin_container .navbarContent .navItem {
    color: white;
    font-size: 15px;
    position: relative;
    padding: 1%;
    cursor: pointer;
}

.admin_container .navbarContent .navItem:hover {
    background: rgba(0,0,0,0.8);
    font-size: 16px;
    color: white;
    border-radius: 5px;
}

.admin_container .sidebarContent {
    position: fixed;
    display: flex;
    width: 17%;
    height: 100%;
    box-sizing: 0;
    z-index: 0;
    font-size: 1.2rem;
    background: #232526;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #414345, #232526);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #414345, #232526); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    justify-content: center;
}

.admin_container .sidebarContent .imglogo {
    position: fixed;
    height: 10%;
    width: 10%;
    display: flex;
    justify-content: center;
}

.admin_container .sidebarContent .imglogo .logo {
    position: relative;
    border-radius: 100px;
    margin-top: 5%;
    box-shadow: 0 0 20px #00000033;
    background-color: gray;
}

.admin_container .sidebarContent .sideBarBody {
    width: 100%;
    height: 82%;
    position: relative;
    margin-top: 50%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    scrollbar-width: none;
    overflow-y: scroll; 
    overflow-x: auto hidden; 
} 



.admin_container .sidebarContent .sideBarBody .sideBarItem {
    width: 90%;
    height: 100%;
    margin-left: 5%;
    position: relative;
    display: flex;
    justify-content: left;
    cursor: pointer;
    color:white;
    font-size: 15px;
    padding: 8px;
    border-bottom: 1px solid rgba(0,0,0,0.2);
} 

.admin_container .sidebarContent .sideBarBody .sideBarItem:hover {
    color: white;
    border-radius: 5px;
    background: rgba(0,0,0,0.8);
}

.admin_container .appContent {
    position: fixed;
    width: 81%;
    height: 87%;
    margin-left: 17.5%;
    margin-top: 6%;
    box-sizing: 0;
    display: inline-flex;
    justify-content: center;
    border-radius: 5px;
    z-index: 0;
    font-size: 0.9rem;
    border: 1px solid black;
    box-shadow: 5px 5px 5px #00000019;
}
