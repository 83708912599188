.PurchaseOrderReportContent{
    width: 3508px;
    z-index: 9999999;
}

.PurchaseOrderReportContent .ReportHeader {
  margin-top: 10%;
  margin-left: 5%;
}

.PurchaseOrderReportContent .ReportHeader .ReportTitle{
    font-size: 100px;
    font-weight: bold;
    text-align: center;
    margin-top: -15%;
}


.PurchaseOrderReportContent .ReportHeader .ReportBillNo{
    font-size: 70px;
    text-align: left;
    margin-top: 1px;
}

.PurchaseOrderReportContent .ReportHeader .PrintDate{
    font-size: 70px;
    text-align: left;
    margin-top: 1px;
}

.PurchaseOrderReportContent .ReportHeader .ReportLocation{
    font-size: 70px;
    text-align: left;
    margin-top: 1px;
}


.PurchaseOrderReportContent .ReportBody .HeadLine{
    font-size: 80px;
}
 
.PurchaseOrderReportContent .ReportBody{
    margin-left: 5%;
    margin-right: 5%;
}

.PurchaseOrderReportContent .ReportBody .ReportBodyHeader {
    margin-top: 5%;
    font-size: 60px;
    display: flex;
}


.PurchaseOrderReportContent .ReportBody .ReportBodyHeader .CountNumber {
    font-size: 60px;
    width: 10%;
}
.PurchaseOrderReportContent .ReportBody .ReportBodyHeader .Barcode {
    font-size: 60px;
    width: 15%;
}
.PurchaseOrderReportContent .ReportBody .ReportBodyHeader .LaoName {
    font-size: 60px;
    width: 15%;
    display: flex;
    justify-content: right;
}
.PurchaseOrderReportContent .ReportBody .ReportBodyHeader .ProductType {
    font-size: 60px;
    width: 15%;
    display: flex;
    justify-content: right;
}
.PurchaseOrderReportContent .ReportBody .ReportBodyHeader .Category {
    font-size: 60px;
    width: 15%;
    display: flex;
    justify-content: right;
}
.PurchaseOrderReportContent .ReportBody .ReportBodyHeader .Brand {
    font-size: 60px;
    width: 15%;
    display: flex;
    justify-content: right;
}
.PurchaseOrderReportContent .ReportBody .ReportBodyHeader .Quantity {
    font-size: 60px;
    width: 20%;
    display: flex;
    justify-content: right;
}



.PurchaseOrderReportContent .ReportBody .ReportDetail .Items .Detail {
    text-align: left;
}

.PurchaseOrderReportContent .ReportBody .ReportDetail .Items .Detail .mds {
    display: flex;
}

.PurchaseOrderReportContent .ReportBody .ReportDetail .Items .NO {
    font-size: 60px;
    width: 10%;
}
.PurchaseOrderReportContent .ReportBody .ReportDetail .Items .Barcode {
    font-size: 60px;
    width: 15%;
}
.PurchaseOrderReportContent .ReportBody .ReportDetail .Items .LaoName {
    font-size: 60px;
    width: 15%;
    display: flex;
    justify-content: right;
}
.PurchaseOrderReportContent .ReportBody .ReportDetail .Items .ProductType {
    font-size: 60px;
    width: 15%;
    display: flex;
    justify-content: right;
}
.PurchaseOrderReportContent .ReportBody .ReportDetail .Items .Category {
    font-size: 60px;
    width: 15%;
    display: flex;
    justify-content: right;
}
.PurchaseOrderReportContent .ReportBody .ReportDetail .Items .Brand {
    font-size: 60px;
    width: 15%;
    display: flex;
    justify-content: right;
}

.PurchaseOrderReportContent .ReportBody .ReportDetail .Items .Quantity {
    font-size: 60px;
    width: 20%;
    display: flex;
    justify-content: right;
}

.PurchaseOrderReportContent .ReportBody .ReportBodyFooter {
    font-size: 60px;
    display: flex;
}

.PurchaseOrderReportContent .ReportBody .ReportBodyFooter .Total {
    font-size: 60px;
    width: 70%;
    display: flex;
    justify-content: right;
}

.PurchaseOrderReportContent .ReportBody .ReportBodyFooter .Quantity {
    font-size: 60px;
    width: 10%;
    display: flex;
    justify-content: right;
}

.PurchaseOrderReportContent .ReportBody .ReportBodyFooter .SubTotal {
    font-size: 60px;
    width: 20%;
    display: flex;
    justify-content: right;
}



.PurchaseOrderReportContent .ReportBody .ReportSign {
    margin-top: 5%;
    font-size: 60px;
    display: flex;
}

.PurchaseOrderReportContent .ReportBody .ReportSign .Manager {
    font-size: 60px;
    width: 20%;
    display: flex;
    justify-content: right;
}
.PurchaseOrderReportContent .ReportBody .ReportSign .StockAdmin {
    font-size: 60px;
    width: 40%;
    display: flex;
    justify-content: right;
}

.PurchaseOrderReportContent .ReportBody .ReportSign .finance {
    font-size: 60px;
    width: 30%;
    display: flex;
    justify-content: right;
}

.PurchaseOrderReportContent .ReportBody .Footer {
    font-size: 60px;
    width: 20%;
    margin-top: 2%;
    display: grid;
}

.PurchaseOrderReportContent .ReportBody .Footer .f1 {
    font-size: 60px;
    display: flex;
    justify-content: right;
}

.PurchaseOrderReportContent .ReportBody .Footer .f2 {
    font-size: 60px;
    display: flex;
    justify-content: right;
}

.PurchaseOrderReportContent .ReportBody .Footer .f3 {
    font-size: 60px;
    display: flex;
    justify-content: right;
}

.PurchaseOrderReportContent .ReportHeader .ReportTitle .titleHeader1{
  font-size: 80px;
}

.PurchaseOrderReportContent .ReportHeader .ReportTitle .titleHeader2{
  font-size: 80px;
}

.PurchaseOrderReportContent .ReportHeader .ReportTitle .titleHeader3{
  font-size: 100px;
}



.PurchaseOrderReportContent .ReportHeader .profile-img {
  width: 15%;
  height: 9%;
  left: 50%;
  box-shadow: 0 0 20px #00000033;
}
   