body {
    font-family: 'Phetsarath OT', sans-serif;
  }
  
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  
.formContent {
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: 0;
    padding: 20px;
}

.formContent  .Header{
    position: relative;
    width: 100%;
}

.formContent  .Header .title{
    font-size: 1.5rem;
    font-weight: bold;
}

.formContent  .Header .txtSearch{
    margin-top: 12px;
    display: flex;
    font-size: 1.2rem;
    font-weight: bold;
}

.formContent  .Header .txtSearch .btn{
    margin-left: 1%;
    height: 40px;
}

.formContent  .Header .txtSearch .btnSearch{
    margin-top: 28px;
}

.formContent  .Header .txtSearch .Location{
    margin-left: 10px;
}

.formContent  .Header .txtSearch .StartedDate{
    margin-left: 10px;
}

.formContent  .Header .txtSearch .EndedDate{
    margin-left: 10px;
}


.formContent .Body {
    margin-top: 12px;
    height: 84%;
    overflow: auto;
    scrollbar-width: none;
    overflow-y: scroll; 
    overflow-x: auto hidden; 
}


.formContent .Body .styled-table {
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    min-width: 100%;
}

.formContent .Body .styled-table thead tr th {
    background-color: rgba(0,0,0,.6);
    color: white;
    text-align: left;
    padding: 8px 15px;
}

.formContent .Body.styled-table th,
.formContent .Body .styled-table td {
    padding: 8px 15px;
}

.formContent .Body .styled-table tbody tr {
    border-bottom: 1px solid #dee2e6;
}

.formContent .Body .styled-table tbody tr td {
    text-align: left;
}

.formContent .Body .styled-table tbody tr:nth-of-type(even) {
    background-color: rgba(0,0,0,.05);
}

.formContent .Body .styled-table tbody tr:last-of-type {
    border-bottom: 2px solid #dee2e6;
}

.formContent .Body .styled-table tbody tr.active-row {
    font-weight: bold;
    color: #009879;
}
