.paymentForm {
  width: 100%;
  height: 100%;
  margin-top: -8%;
  margin-left: -20%;
  overflow: hidden;
  justify-content: center;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5) !important;
  z-index: 999;
  }
  
  .PaymentContents::-webkit-scrollbar {
    display: none;
  }
  
  .paymentForm .PaymentContents {
    width: 80%;
    height: 90%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    left: 0;
    margin-top: 2%;
    padding: 10px;
    border-radius: 1rem;
    overflow: auto;
    scrollbar-width: none;
    overflow-y: scroll;
    overflow: hidden;
    background-color: #dfe2eb;
    box-shadow: 3px 3px 10px rgba(8, 8, 8, 0.2);
  }
  
  .paymentForm .PaymentContents .leftConten {
    position: fixed;
    width: 39.2%;
    height: 83%;
    margin: 15px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    padding: 20px;
    background-color: #dfe2eb;
    box-shadow: 3px 3px 10px rgba(8, 8, 8, 0.2);
  }

  .leftConten .Header {
    font-size: 14px;
    font-weight: bold;
  }

  .inputAmout {
    position: relative;
    height: 35%;
    display: grid;
    align-content: flex-start;
  }

  .selectContent {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-top: 2%;
    width: 100%;
    height: 100%;
  }

  .selectContent .PaymentType {
    position: relative;
    display: grid;
    width: 100%;
    height: 100%;
    padding: 1%;
  }

  .selectContent .PaymentType .cbPaymentType {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 1%;
    border-radius: 3px;
  }

  .selectContent .Currency {
    position: relative;
    display: grid;
    width: 100%;
    height: 100%;
    padding: 1%;
    border-radius: 3px;
  }

  .selectContent .DiscountType {
    position: relative;
    display: grid;
    width: 100%;
    height: 100%;
    padding: 1%;
    border-radius: 3px;
  }

  .inputContent{
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-top: 2.5%;
    width: 100%;
    height: 100%;
  }

  .inputContent .txtPaidAmount{
    position: relative;
    display: grid;
    width: 100%;
    height: 100%;
    padding: 1%;
    border-radius: 3px;
  }

  .inputContent .txtPaidMore{
    position: relative;
    display: grid;
    width: 100%;
    height: 100%;
    padding: 1%;
    border-radius: 3px;
  }

  .inputMoneyContent {
    background: rgba(0, 0, 0, 0.2);
    position: relative;
    width: 100%;
    height: 50%;
    border-radius: 20px;
    padding: 10px;
  }
  
  .inputMoneyContent .group1 {
    position: relative;
    display: flex;
    margin-top: 2%;
    justify-content: center;
  }
  
  .inputMoneyContent .group1 img {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    cursor: pointer;
  }
  
  .inputMoneyContent .group2 {
    position: relative;
    display: flex;
    margin-top: 2%;
    justify-content: center;
  }
  
  .inputMoneyContent .group2 img {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    cursor: pointer;
  }
  
  .inputMoneyContent .group3 {
    position: relative;
    display: flex;
    margin-top: 2%;
    justify-content: center;
  }
  
  .inputMoneyContent .group3 img {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    cursor: pointer;
  }
  
  .money {
    background-color: white;
    margin-left: 5%;
    width: 150px;
    height: 60px;
    border-radius: 8px;
  }
  

  /* end of leftContent  -------------------------------------------------------------------------------------- */
  
  .paymentForm .PaymentContents .rightContent {
    position: fixed;
    width: 38%;
    height: 83%;
    margin: 15px;
    margin-left: 40%;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    color: white;
    padding: 20px;
    transition: 0.5s;
    box-shadow: 3px 3px 10px rgba(8, 8, 8, 0.2);
    background: #141E30;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #243B55, #141E30);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #243B55, #141E30); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  }
  
  .rightContent .Header {
    position: relative;
    display: flex;
    justify-content: right;
  }

  .rightContent .Header .btnclose {
    position: relative;
    border-radius: 10px;
    border: 1px solid #d9d9d9;
    color: white;
    display: grid;
    justify-content: center;
    width: 37px;
    height: 22px;
    cursor: pointer;
  }
  .rightContent .Header .btnclose:hover {
    color: white;
    background-color: #ff2251;
  }

  .rightContent .body {
    height: 70%;
    padding: 10px;
    border-radius: 15px;
  }

  .rightContent .body .subtotal {
    position: relative;
    display: flex;
    font-size: 16px;
    font-weight: bold;
    color: #00c851;
    margin-top: 2%;
    justify-content: space-between;
  }

  .rightContent .body .discout {
    position: relative;
    display: flex;
    font-size: 14px;
    justify-content: space-between;
  }
  
  .rightContent .body .discout span {
    font-size: 14px;
    margin-top: 2%;
  }

  .rightContent .body .discout .txtDiscount {
    font-size: 14px;
    margin-top: 2%;
    text-align: right;
  }
  
  .rightContent .body .tax {
    position: relative;
    display: flex;
    font-size: 14px;
    margin-top: 2%;
    justify-content: space-between;
  }

  .rightContent .body .nettotal {
    position: relative;
    display: flex;
    font-size: 16px;
    font-weight: bold;
    margin-top: 1%;
    justify-content: space-between;
    color: white;
  }
  
  .rightContent .body .paidAmount {
    position: relative;
    display: flex;
    font-size: 14px;
    font-weight: bold;
    margin-top: 2%;
    justify-content: space-between;
  }
  
  .rightContent .body .changeAmount {
    position: relative;
    display: flex;
    font-size: 14px;
    font-weight: bold;
    margin-top: 1%;
    justify-content: space-between;
  }
  
  .rightContent .body .changeAmount strong {
    color: red;
  }
  
  .rightContent .body .MemerCard{
    position: relative;
    margin-top: 1%;
    height: 45%;
    border-radius: 15px;
    padding: 10px;
    border: 1px solid #d9d9d9;
  }

  .rightContent .body .MemerCard .CardNumber{
    position: relative;
    display: flex;
    font-size: 14px;
    font-weight: bold;
    justify-content: space-between;
  }

  .rightContent .body .MemerCard .CardNumber label{
    margin-top: 2%;
  }

  .rightContent .body .MemerCard .CardNumber .input{
    width: 50%;
    text-align: right;
  }

  .rightContent .body .MemerCard .Name{
    position: relative;
    display: flex;
    margin-top: 1%;
    font-size: 14px;
    font-weight: bold;
    justify-content: space-between;
  }

  .rightContent .body .MemerCard .Balance{
    position: relative;
    display: flex;
    margin-top: 1%;
    font-size: 14px;
    font-weight: bold;
    justify-content: space-between;
  }

  .rightContent .body .MemerCard .NewBalance{
    position: relative;
    display: flex;
    margin-top: 1%;
    font-size: 14px;
    font-weight: bold;
    justify-content: space-between;
  }

  .rightContent .footer {
    position: relative;
    display: flex;
    margin-top: 1%;
    height: 18%;
    font-weight: bold;
    padding: 10px;
    justify-content: center;
  }
  

  .rightContent .footer .btn-Payment {
    width: 60%;
    height: 60%;
    font-size: 24px;
  }
  
  .rightContent .footer .btn-Payment .btn {
    color: white;
    background-color: #28a745;
    width: 100%;
    height: 100%;
  }
  
  .rightContent .footer .btn-Payment .btn:hover {
    background-color: black;
    color: white;
  }
  