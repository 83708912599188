.CashReportContent{
    width: 29.7cm;
    min-height: 21cm;
}

@page {
    size: A4;
    margin: 0;
  }

.CashReportContent .ReportHeader {
  margin-top: 10%;
  margin-left: 5%;
}

.CashReportContent .ReportHeader .ReportTitle{
    font-weight: bold;
    text-align: center;
    margin-top: -15%;
}

.CashReportContent .ReportHeader .ReportTitle .titleHeader1{
    font-size: 19px;
}
.CashReportContent .ReportHeader .ReportTitle .titleHeader2{
    font-size: 19px;
}

.CashReportContent .ReportHeader .ReportTitle .titleHeader3{
    font-size: 25px;
}
  

.CashReportContent .ReportHeader .ReportBillNo{
    font-size: 16px;
    text-align: left;
    margin-top: 10%;
}

.CashReportContent .ReportHeader .PrintDate{
    font-size: 16px;
    text-align: left;
    margin-top: 1px;
}

.CashReportContent .ReportHeader .ReportLocation{
    font-size: 16px;
    text-align: left;
    margin-top: 1px;
}

 
.CashReportContent .ReportBody{
    margin-left: 5%;
    margin-right: 5%;
}

.CashReportContent .ReportBody .ReportBodyHeader {
    margin-top: 5%;
    font-size: 19px;
    display: flex;
}


.CashReportContent .ReportBody .ReportBodyHeader .CountNumber {
    width: 10%;
}
.CashReportContent .ReportBody .ReportBodyHeader .PaymentType {
    width: 20%;
}
.CashReportContent .ReportBody .ReportBodyHeader .SubTotal {
    width: 20%;
    display: flex;
    justify-content: right;
}
.CashReportContent .ReportBody .ReportBodyHeader .Discount {
    width: 25%;
    display: flex;
    justify-content: right;
}
.CashReportContent .ReportBody .ReportBodyHeader .PaidAmount {
    width: 25%;
    display: flex;
    justify-content: right;
}


.CashReportContent .ReportBody .ReportDetail .Items .Detail {
    text-align: left;
}

.CashReportContent .ReportBody .ReportDetail .Items .Detail .mds {
    display: flex;
}

.CashReportContent .ReportBody .ReportDetail .Items .NO {
    font-size: 16px;
    width: 10%;
}
.CashReportContent .ReportBody .ReportDetail .Items .PaymentType {
    font-size: 16px;
    width: 20%;
}
.CashReportContent .ReportBody .ReportDetail .Items .SubTotal {
    font-size: 16px;
    width: 20%;
    display: flex;
    justify-content: right;
}
.CashReportContent .ReportBody .ReportDetail .Items .Discount {
    font-size: 16px;
    width: 25%;
    display: flex;
    justify-content: right;
}
.CashReportContent .ReportBody .ReportDetail .Items .PaidAmount {
    font-size: 16px;
    width: 25%;
    display: flex;
    justify-content: right;
}

.CashReportContent .HeadLineCash .footer-line{
    width: 100%;
    margin-top: 1%;
    margin-bottom: 1%;
    border-top: 2px dashed #000; border-right: none; border-bottom: none; border-left: none;
}

.CashReportContent .ReportBody .ReportBodyFooter {
    font-size: 19px;
    display: flex;
}

.CashReportContent .ReportBody .ReportBodyFooter .Total {
    font-size: 19px;
    width: 20%;
    display: flex;
    justify-content: right;
}

.CashReportContent .ReportBody .ReportBodyFooter .SubTotal {
    font-size: 19px;
    width: 30%;
    display: flex;
    justify-content: right;
}

.CashReportContent .ReportBody .ReportBodyFooter .Discount {
    font-size: 19px;
    width: 25%;
    display: flex;
    justify-content: right;
}

.CashReportContent .ReportBody .ReportBodyFooter .PaidAmount {
    font-size: 19px;
    width: 25%;
    display: flex;
    justify-content: right;
}



.CashReportContent .ReportBody .ReportSign {
    margin-top: 5%;
    font-size: 19px;
    display: flex;
}

.CashReportContent .ReportBody .ReportSign .Manager {
    font-size: 19px;
    width: 20%;
    display: flex;
    justify-content: right;
}
.CashReportContent .ReportBody .ReportSign .StockAdmin {
    font-size: 19px;
    width: 40%;
    display: flex;
    justify-content: right;
}

.CashReportContent .ReportBody .ReportSign .finance {
    font-size: 19px;
    width: 30%;
    display: flex;
    justify-content: right;
}

.CashReportContent .ReportBody .Footer {
    font-size: 19px;
    width: 20%;
    margin-top: 2%;
    display: grid;
}

.CashReportContent .ReportBody .Footer .f1 {
    font-size: 19px;
    display: flex;
    justify-content: right;
}

.CashReportContent .ReportBody .Footer .f2 {
    font-size: 19px;
    display: flex;
    justify-content: right;
}

.CashReportContent .ReportBody .Footer .f3 {
    font-size: 19px;
    display: flex;
    justify-content: right;
}

.CashReportContent .ReportHeader .profile-img {
  width: 15%;
  height: 9%;
  left: 50%;
  box-shadow: 0 0 20px #00000033;
}
   

