.TransactionReportContent{
    width: 3508px;
    z-index: 9999999;
}

.TransactionReportContent .ReportHeader {
  margin-top: 10%;
  margin-left: 5%;
}

.TransactionReportContent .ReportHeader .ReportTitle{
    font-size: 100px;
    font-weight: bold;
    text-align: center;
    margin-top: -15%;
}


.TransactionReportContent .ReportHeader .ReportBillNo{
    font-size: 70px;
    text-align: left;
    margin-top: 1px;
}

.TransactionReportContent .ReportHeader .PrintDate{
    font-size: 70px;
    text-align: left;
    margin-top: 1px;
}

.TransactionReportContent .ReportHeader .ReportLocation{
    font-size: 70px;
    text-align: left;
    margin-top: 1px;
}


.TransactionReportContent .ReportBody .HeadLine{
    font-size: 80px;
}
 
.TransactionReportContent .ReportBody{
    margin-left: 5%;
    margin-right: 5%;
}

.TransactionReportContent .ReportBody .ReportBodyHeader {
    margin-top: 5%;
    font-size: 60px;
    display: flex;
}


.TransactionReportContent .ReportBody .ReportBodyHeader .CountNumber {
    font-size: 60px;
    width: 10%;
}
.TransactionReportContent .ReportBody .ReportBodyHeader .BillNo {
    font-size: 60px;
    width: 10%;
}
.TransactionReportContent .ReportBody .ReportBodyHeader .PaymentType {
    font-size: 60px;
    width: 15%;
    display: flex;
    justify-content: right;
}
.TransactionReportContent .ReportBody .ReportBodyHeader .Currency {
    font-size: 60px;
    width: 10%;
    display: flex;
    justify-content: right;
}
.TransactionReportContent .ReportBody .ReportBodyHeader .SubTotal {
    font-size: 60px;
    width: 20%;
    display: flex;
    justify-content: right;
}
.TransactionReportContent .ReportBody .ReportBodyHeader .Discount {
    font-size: 60px;
    width: 15%;
    display: flex;
    justify-content: right;
}
.TransactionReportContent .ReportBody .ReportBodyHeader .NetTotal {
    font-size: 60px;
    width: 20%;
    display: flex;
    justify-content: right;
}



.TransactionReportContent .ReportBody .ReportDetail .Items .Detail {
    text-align: left;
}

.TransactionReportContent .ReportBody .ReportDetail .Items .Detail .mds {
    display: flex;
}

.TransactionReportContent .ReportBody .ReportDetail .Items .NO {
    font-size: 60px;
    width: 10%;
}
.TransactionReportContent .ReportBody .ReportDetail .Items .BillNo {
    font-size: 60px;
    width: 10%;
}
.TransactionReportContent .ReportBody .ReportDetail .Items .PaymentType {
    font-size: 60px;
    width: 15%;
    display: flex;
    justify-content: right;
}
.TransactionReportContent .ReportBody .ReportDetail .Items .Currency {
    font-size: 60px;
    width: 10%;
    display: flex;
    justify-content: right;
}
.TransactionReportContent .ReportBody .ReportDetail .Items .SubTotal {
    font-size: 60px;
    width: 20%;
    display: flex;
    justify-content: right;
}
.TransactionReportContent .ReportBody .ReportDetail .Items .Discount {
    font-size: 60px;
    width: 15%;
    display: flex;
    justify-content: right;
}

.TransactionReportContent .ReportBody .ReportDetail .Items .NetTotal {
    font-size: 60px;
    width: 20%;
    display: flex;
    justify-content: right;
}

.TransactionReportContent .HeadLineTransaction .footer-line{
    width: 100%;
    margin-top: 1%;
    margin-bottom: 1%;
    border-top: 8px dashed #000; border-right: none; border-bottom: none; border-left: none;
}


.TransactionReportContent .ReportBody .ReportBodyFooter {
    font-size: 60px;
    display: flex;
}

.TransactionReportContent .ReportBody .ReportBodyFooter .Total {
    font-size: 60px;
    width: 45%;
    display: flex;
    justify-content: right;
}

.TransactionReportContent .ReportBody .ReportBodyFooter .SubTotal {
    font-size: 60px;
    width: 20%;
    display: flex;
    justify-content: right;
}

.TransactionReportContent .ReportBody .ReportBodyFooter .Discount {
    font-size: 60px;
    width: 15%;
    display: flex;
    justify-content: right;
}

.TransactionReportContent .ReportBody .ReportBodyFooter .PaidAmount {
    font-size: 60px;
    width: 20%;
    display: flex;
    justify-content: right;
}



.TransactionReportContent .ReportBody .ReportSign {
    margin-top: 5%;
    font-size: 60px;
    display: flex;
}

.TransactionReportContent .ReportBody .ReportSign .Manager {
    font-size: 60px;
    width: 20%;
    display: flex;
    justify-content: right;
}
.TransactionReportContent .ReportBody .ReportSign .StockAdmin {
    font-size: 60px;
    width: 40%;
    display: flex;
    justify-content: right;
}

.TransactionReportContent .ReportBody .ReportSign .finance {
    font-size: 60px;
    width: 30%;
    display: flex;
    justify-content: right;
}

.TransactionReportContent .ReportBody .Footer {
    font-size: 60px;
    width: 20%;
    margin-top: 2%;
    display: grid;
}

.TransactionReportContent .ReportBody .Footer .f1 {
    font-size: 60px;
    display: flex;
    justify-content: right;
}

.TransactionReportContent .ReportBody .Footer .f2 {
    font-size: 60px;
    display: flex;
    justify-content: right;
}

.TransactionReportContent .ReportBody .Footer .f3 {
    font-size: 60px;
    display: flex;
    justify-content: right;
}

.TransactionReportContent .ReportHeader .ReportTitle .titleHeader1{
  font-size: 80px;
}

.TransactionReportContent .ReportHeader .ReportTitle .titleHeader2{
  font-size: 80px;
}

.TransactionReportContent .ReportHeader .ReportTitle .titleHeader3{
  font-size: 100px;
}



.TransactionReportContent .ReportHeader .profile-img {
  width: 15%;
  height: 9%;
  left: 50%;
  box-shadow: 0 0 20px #00000033;
}
   
