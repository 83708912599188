.tableFormContent {
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: 0;
    padding: 20px;
}

.tableFormContent  .tableContent{
    position: relative;
    width: 100%;
    height: 100%;
    background-color: whitesmoke;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}


.tableFormContent  .tableContent .tableBody{
    position: relative;
    width: 100%;
    height: 100%;
    overflow: auto;
    overflow-y: scroll; 
    overflow-x: auto hidden; 
}

.tableFormContent  .tableContent .tableBody .tableItem{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 10px;
}

.item {
    width: 150px;
    position: relative;
    height: 100px;
    margin-top: 10px;
    background-color: green;
    box-shadow: 5px 5px 5px #00000024;
    text-align: center;
    font-size: 20px;
    border-radius: 15px;
    margin-left: 5px;
    font-size: 15px;
    font-weight: bold;
    color: white;
    left: 0;
    border: 1.5px solid #d9d9d9;
    cursor: pointer;
}

.item:hover {
    color: black;
    background-color: #d9d9d9;
}

.item .item-body {
    position: relative;
    padding-top: 5%;
}

.item .item-body .name {
    margin-top: 15%;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    padding: 10px;
}


@media screen and (max-width: 100px) {
    .item {
        width: 96%;
    }

    .item .item-body {
        padding: 10px 20px;
    }
}


.item2 {
    width: 150px;
    position: relative;
    height: 100px;
    margin-top: 10px;
    background-color: red;
    box-shadow: 5px 5px 5px #00000024;
    text-align: center;
    font-size: 20px;
    border-radius: 15px;
    margin-left: 5px;
    font-size: 15px;
    font-weight: bold;
    color: white;
    left: 0;
    border: 1.5px solid #d9d9d9;
    cursor: pointer;
}

.item2:hover {
    color: black;
    background-color: #d9d9d9;
}

.item2 .item-body2 {
    position: relative;
    padding-top: 5%;
}

.item2 .item-body2 .name2 {
    margin-top: 15%;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    padding: 10px;
}


@media screen and (max-width: 100px) {
    .item2 {
        width: 96%;
    }

    .item2 .item-body2 {
        padding: 10px 20px;
    }
}

