
.TempForm {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    z-index: 99999;
    background-color: #dfe2eb;
  }
  
    .TempForm .txtSearch{
        width: 100%;
        height: 40px;
        position: relative;
        display: flex;
        margin: 1%;
    }

    .TempForm .txtSearch .txt{
      width: 200px;
      height: 30px;
      margin-left: 1%;
      z-index: 99999;
    }

    .TempForm .txtSearch button{
      width: 100px;
      height: 36px;
      margin-left: 1%;
      margin-top: 2%;
      z-index: 99999;
    }

    .TempForm .txtSearch .qty{
      width: 100px;
      height: 30px;
      margin-left: 1%;
      z-index: 99999;
    }

  .TempContent::-webkit-scrollbar {
    display: none;
  }
  
  .TempForm .TempContent {
    width: 95%;
    height: 95%;
    padding: 10px;
    position: relative;
    overflow: auto;
    margin-top: 2%;
    scrollbar-width: none;
    overflow-y: scroll;
    background-color: #dfe2eb;
  }

  .TempForm .Header {
    width: 100%;
    height: 10%;
    padding: 10px;
    position: relative;
  }

  .TempForm .Header span {
    font-size: 20px;
    margin-left: 1%;
  }


  .TempForm .TempContent .TempItem {
    position: relative;
    width: 95%;
    height: 40px;
    margin: 1%;
    border-radius: 5px;
  }
  
  .TempForm .TempContent .TempItem:hover{
    background-color: gray;
    color: white;
  }

  .TempForm .TempContent .TempItem .TempBody {
    position: relative;
    display: flex;
    border-radius: 5px;
    height: 40px;
    padding: 10px;
    font-size: 17px;
    justify-content: space-between;
    background: rgba(0,0,0,0.1);
  }

  .TempForm .TempContent .TempItem .TempBody .btnAdd {
      height: 30px;
      width: 50px;
      margin-top: -3px;
      padding: 0%;
  }


  

