.BillContent{
    width: 260px;
    height: auto;
    z-index: 9999999;
}

.BillContent .BillHeader {
  margin-top: 3%;
}

.BillContent .BillHeader .BillTitle{
    font-size: 20px;
    font-weight: bold;
    text-align: center;
}


.BillContent .BillHeader .Cashier{
    font-size: 12px;
    text-align: left;
    margin-top: 10px;
}

.BillContent .BillHeader .ShowDate{
    font-size: 12px;
    text-align: left;
}

.BillContent .BillHeader .LocationName{
    font-size: 12px;
    text-align: left;
}

.BillContent .BillHeader .HeadLine{
    font-size: 20px;
    text-align: center;
}

.BillContent .BodyHeader {
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    text-align: right;
}


.BillContent .BillDetail{
   font-size:  12px;
}

.BillContent .BillDetail .GiftCardNO {
    font-size: 14px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
}


.BillContent .BillDetail .Price {
    font-size: 12px;
    display: flex;
    font-weight: bold;
    justify-content: space-between;
}

.BillContent .BillDetail .paymentType {
    font-size: 14px;
    display: flex;
    justify-content: space-between;
}

.BillContent .BillDetail .paidAmount {
    font-size: 14px;
    display: flex;
    font-weight: bold;
    justify-content: space-between;
}

.BillContent .BillDetail .changeAmount {
    font-size: 14px;
    display: flex;
    justify-content: space-between;
}

.BillContent .BillDetail .Thankyou {
    font-size: 14px;
    display: flex;
    justify-content: center;
}
