.popUpForm {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    padding: 2%;
    margin-left: -20px;
    margin-top: -20px;
    overflow: hidden;
    background: rgba(0,0,0,0.7);
    border-radius: 4px;
    justify-content: center;
    z-index: 1;
    
}

.popUpForm .content {
    position: fixed;
    padding: 20px;
    border-radius: 10px;
    background-color: whitesmoke;
}


.popUpForm .content .Header {
    display: flex;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
}

.popUpForm .content .footer {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
}